export const getDataForGraphAPI = async(payload: any) => {
  const options = {
    method: "GET",
    headers: {
      'Content-type': 'application/json',
    }
  }

  try {
    const response = await fetch(`${process.env.REACT_APP_API_ES_CONNECTOR_API}/dashboard-metricas/quantity-transactions-per-hour?start=${payload.start}&end=${payload.end}&size=${payload.size}&commerce=${payload.commerce}`, options);
    return response.json()
  } catch (error) {
    console.error(error);
  }
}

export const getDataForTableAPI = async(payload: any) => {
  console.log(payload);
  const options = {
    method: "GET",
    headers: {
      'Content-type': 'application/json',
    }
  }

  try {
    const response = await fetch(`${process.env.REACT_APP_API_ES_CONNECTOR_API}/dashboard-metricas/data-transactions?start=${payload.payload.start}&end=${payload.payload.end}&size=${payload.pagination.pageSize}&page=${payload.pagination.current}&commerce=${payload.payload.commerce}`, options);
    return response.json()
  } catch (error) {
    console.error(error);
  }
}

export const getDataForTableCSVAPI = async(payload: any) => {
  console.log(payload);
  const options = {
    method: "GET",
    headers: {
      'Content-type': 'application/json',
    }
  }

  try {
    const response = await fetch(`${process.env.REACT_APP_API_ES_CONNECTOR_API}/dashboard-metricas/data-transactions/export?start=${payload.payload.start}&end=${payload.payload.end}&size=${payload.pagination.pageSize}&page=${payload.pagination.current}&commerce=${payload.payload.commerce}`, options);
    return response.json()
  } catch (error) {
    console.error(error);
  }
}

export const getDataForGraphGroupAPI = async(payload: any) => {
  const options = {
    method: "GET",
    headers: {
      'Content-type': 'application/json',
    }
  }

  try {
    const response = await fetch(`${process.env.REACT_APP_API_ES_CONNECTOR_API}/dashboard-metricas/duration-per-transaction?start=${payload.start}&end=${payload.end}&size=${payload.size}&commerce=${payload.commerce}`, options);
    return response.json()
  } catch (error) {
    console.error(error);
  }
}