export const DASHBOARD_ACTIONS = {
  DASHBOARD_ACTIONS_RESET_FILTERS: 'DASHBOARD_ACTIONS_RESET_FILTERS',
  DASHBOARD_ACTIONS_LOAD_DATA_TABLE: 'DASHBOARD_ACTIONS_LOAD_DATA_TABLE',
  DASHBOARD_ACTIONS_RECEIVE_DATA_TABLE: 'DASHBOARD_ACTIONS_RECEIVE_DATA_TABLE',
  DASHBOARD_ACTIONS_LOAD_DATA_TABLE_CSV: 'DASHBOARD_ACTIONS_LOAD_DATA_TABLE_CSV',
  DASHBOARD_ACTIONS_RECEIVE_DATA_TABLE_CSV: 'DASHBOARD_ACTIONS_RECEIVE_DATA_TABLE_CSV',
  DASHBOARD_ACTIONS_AFTER_RECEIVE_DATA_TABLE: 'DASHBOARD_ACTIONS_AFTER_RECEIVE_DATA_TABLE',
  DASHBOARD_ACTIONS_LOAD_DATA_GRAPH: 'DASHBOARD_ACTIONS_LOAD_DATA_GRAPH',
  DASHBOARD_ACTIONS_RECEIVE_DATA_GRAPH: 'DASHBOARD_ACTIONS_RECEIVE_DATA_GRAPH',
  DASHBOARD_ACTIONS_AFTER_RECEIVE_DATA_GRAPH: 'DASHBOARD_ACTIONS_AFTER_RECEIVE_DATA_GRAPH',
  DASHBOARD_ACTIONS_LOAD_DATA_GRAPH_GROUP: 'DASHBOARD_ACTIONS_LOAD_DATA_GRAPH_GROUP',
  DASHBOARD_ACTIONS_RECEIVE_DATA_GRAPH_GROUP: 'DASHBOARD_ACTIONS_RECEIVE_DATA_GRAPH_GROUP',
  DASHBOARD_ACTIONS_AFTER_RECEIVE_DATA_GRAPH_GROUP: 'DASHBOARD_ACTIONS_AFTER_RECEIVE_DATA_GRAPH_GROUP'
}

export const DASHBOARD_KRUGMAN_ACTIONS = {
  DASHBOARD_KRUGMAN_ACTIONS_FORM: 'DASHBOARD_KRUGMAN_ACTIONS_FORM',
  DASHBOARD_KRUGMAN_ACTIONS_RESET_FILTERS: 'DASHBOARD_KRUGMAN_ACTIONS_RESET_FILTERS',
  DASHBOARD_KRUGMAN_ACTIONS_LOAD_INDICATORS: 'DASHBOARD_KRUGMAN_ACTIONS_LOAD_INDICATORS',
  DASHBOARD_KRUGMAN_ACTIONS_RECEIVE_INDICATORS: 'DASHBOARD_KRUGMAN_ACTIONS_RECEIVE_INDICATORS',
  DASHBOARD_KRUGMAN_ACTIONS_LOAD_DATA_TABLE: 'DASHBOARD_KRUGMAN_ACTIONS_LOAD_DATA_TABLE',
  DASHBOARD_KRUGMAN_ACTIONS_RECEIVE_DATA_TABLE: 'DASHBOARD_KRUGMAN_ACTIONS_RECEIVE_DATA_TABLE',
  DASHBOARD_KRUGMAN_ACTIONS_LOAD_DATA_TABLE_CSV: 'DASHBOARD_KRUGMAN_ACTIONS_LOAD_DATA_TABLE_CSV',
  DASHBOARD_KRUGMAN_ACTIONS_RECEIVE_DATA_TABLE_CSV: 'DASHBOARD_KRUGMAN_ACTIONS_RECEIVE_DATA_TABLE_CSV',
  DASHBOARD_KRUGMAN_ACTIONS_LOAD_GRAPH_LINE_CONDITIONAL: 'DASHBOARD_KRUGMAN_ACTIONS_LOAD_GRAPH_LINE_CONDITIONAL',
  DASHBOARD_KRUGMAN_ACTIONS_RECEIVE_GRAPH_LINE_CONDITIONAL: 'DASHBOARD_KRUGMAN_ACTIONS_RECEIVE_GRAPH_LINE_CONDITIONAL',
  DASHBOARD_KRUGMAN_ACTIONS_LOAD_GRAPH_LINE_POLYLINE: 'DASHBOARD_KRUGMAN_ACTIONS_LOAD_GRAPH_LINE_POLYLINE',
  DASHBOARD_KRUGMAN_ACTIONS_RECEIVE_GRAPH_LINE_POLYLINE: 'DASHBOARD_KRUGMAN_ACTIONS_RECEIVE_GRAPH_LINE_POLYLINE',
  DASHBOARD_KRUGMAN_ACTIONS_LOAD_GRAPH_BAR_MULTILINE: 'DASHBOARD_KRUGMAN_ACTIONS_LOAD_GRAPH_BAR_MULTILINE',
  DASHBOARD_KRUGMAN_ACTIONS_RECEIVE_GRAPH_BAR_MULTILINE: 'DASHBOARD_KRUGMAN_ACTIONS_RECEIVE_GRAPH_BAR_MULTILINE',
  DASHBOARD_KRUGMAN_ACTIONS_LOAD_GRAPH_HISTOGRAM: 'DASHBOARD_KRUGMAN_ACTIONS_LOAD_GRAPH_HISTOGRAM',
  DASHBOARD_KRUGMAN_ACTIONS_RECEIVE_GRAPH_HISTOGRAM: 'DASHBOARD_KRUGMAN_ACTIONS_RECEIVE_GRAPH_HISTOGRAM',
  DASHBOARD_KRUGMAN_ACTIONS_LOAD_GRAPH_LINE_MULTIAXIS: 'DASHBOARD_KRUGMAN_ACTIONS_LOAD_GRAPH_LINE_MULTIAXIS',
  DASHBOARD_KRUGMAN_ACTIONS_RECEIVE_GRAPH_LINE_MULTIAXIS: 'DASHBOARD_KRUGMAN_ACTIONS_RECEIVE_GRAPH_LINE_MULTIAXIS',
}