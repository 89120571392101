import moment from "moment";
import { DASHBOARD_KRUGMAN_ACTIONS } from "../actions/index.action";

let defaultState = {
  filters: {
    start: moment().subtract(2, 'days').unix() * 1000,
    end: moment().subtract(1, 'hours').unix() * 1000,
  },
  dataIndicators: null,
  isLoadingDataIndicators: false,
  isLoadedDataIndicators: false,

  dataTable: null,
  isLoadingDataTable: false,
  isLoadedDataTable: false,

  dataGraphConditional: null,
  isLoadingDataGraphConditional: false,
  isLoadedDataGraphConditional: false,

  dataGraphPolyline: null,
  isLoadingDataGraphPolyline: false,
  isLoadedDataGraphPolyline: false,

  dataGraphMultiline: null,
  isLoadingDataGraphMultiline: false,
  isLoadedDataGraphMultiline: false,

  dataGraphHistogram: null,
  isLoadingDataGraphHistogram: false,
  isLoadedDataGraphHistogram: false,
}

const dashboardKrugman = (state = defaultState, action: any) => {
  switch (action.type) {
    case DASHBOARD_KRUGMAN_ACTIONS.DASHBOARD_KRUGMAN_ACTIONS_FORM:
      return {
        ...state,
        filters: action.payload,
        dataIndicators: null,
        dataTable: null,
        dataGraphConditional: null,
        dataGraphPolyline: null,
        dataGraphMultiline: null,
        dataGraphHistogram: null,
      }
    case DASHBOARD_KRUGMAN_ACTIONS.DASHBOARD_KRUGMAN_ACTIONS_LOAD_INDICATORS:
      return {
        ...state,
        dataIndicators: null,
        isLoadingDataIndicators: true,
        isLoadedDataIndicators: false,
      }
    case DASHBOARD_KRUGMAN_ACTIONS.DASHBOARD_KRUGMAN_ACTIONS_RECEIVE_INDICATORS:
      return {
        ...state,
        dataIndicators: action.data,
        isLoadingDataIndicators: false,
        isLoadedDataIndicators: true,
      }
    case DASHBOARD_KRUGMAN_ACTIONS.DASHBOARD_KRUGMAN_ACTIONS_LOAD_DATA_TABLE:
      return {
        ...state,
        dataTable: null,
        isLoadingDataTable: true,
        isLoadedDataTable: false,

      }
    case DASHBOARD_KRUGMAN_ACTIONS.DASHBOARD_KRUGMAN_ACTIONS_RECEIVE_DATA_TABLE:
      return {
        ...state,
        dataTable: action.data,
        isLoadingDataTable: false,
        isLoadedDataTable: true,

      }
    case DASHBOARD_KRUGMAN_ACTIONS.DASHBOARD_KRUGMAN_ACTIONS_LOAD_GRAPH_LINE_CONDITIONAL:
      return {
        ...state,
        dataGraphConditional: null,
        isLoadingDataGraphConditional: true,
        isLoadedDataGraphConditional: false,
      }
    case DASHBOARD_KRUGMAN_ACTIONS.DASHBOARD_KRUGMAN_ACTIONS_RECEIVE_GRAPH_LINE_CONDITIONAL:
      return {
        ...state,
        dataGraphConditional: action.data,
        isLoadingDataGraphConditional: false,
        isLoadedDataGraphConditional: true,
      }
    case DASHBOARD_KRUGMAN_ACTIONS.DASHBOARD_KRUGMAN_ACTIONS_LOAD_GRAPH_LINE_POLYLINE:
      return {
        ...state,
        dataGraphPolyline: null,
        isLoadingDataGraphPolyline: true,
        isLoadedDataGraphPolyline: false,
      }
    case DASHBOARD_KRUGMAN_ACTIONS.DASHBOARD_KRUGMAN_ACTIONS_RECEIVE_GRAPH_LINE_POLYLINE:
      return {
        ...state,
        dataGraphPolyline: action.data,
        isLoadingDataGraphPolyline: false,
        isLoadedDataGraphPolyline: true,
      }
    case DASHBOARD_KRUGMAN_ACTIONS.DASHBOARD_KRUGMAN_ACTIONS_LOAD_GRAPH_BAR_MULTILINE:
      return {
        ...state,
        dataGraphMultiline: null,
        isLoadingDataGraphMultiline: true,
        isLoadedDataGraphMultiline: false,
      }
    case DASHBOARD_KRUGMAN_ACTIONS.DASHBOARD_KRUGMAN_ACTIONS_RECEIVE_GRAPH_BAR_MULTILINE:
      return {
        ...state,
        dataGraphMultiline: action.data,
        isLoadingDataGraphMultiline: false,
        isLoadedDataGraphMultiline: true,
      }
    case DASHBOARD_KRUGMAN_ACTIONS.DASHBOARD_KRUGMAN_ACTIONS_LOAD_GRAPH_HISTOGRAM:
      return {
        ...state,
        dataGraphHistogram: null,
        isLoadingDataGraphHistogram: true,
        isLoadedDataGraphHistogram: false,
      }
    case DASHBOARD_KRUGMAN_ACTIONS.DASHBOARD_KRUGMAN_ACTIONS_RECEIVE_GRAPH_HISTOGRAM:
      return {
        ...state,
        dataGraphHistogram: action.data,
        isLoadingDataGraphHistogram: false,
        isLoadedDataGraphHistogram: true,
      }
    default:
      return state
  }

}

export default dashboardKrugman;
