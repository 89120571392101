import { takeLatest, put, call } from "redux-saga/effects";
import { Response } from "../../models/Response";
import { getDataForTableAPI, getDataForGraphAPI, getDataForGraphGroupAPI, getDataForTableCSVAPI } from '../../services/dashboard.service';
import { DASHBOARD_ACTIONS } from '../actions/index.action';
import { receiveDataForGraph, receiveDataForTable, receiveDataForGraphGroup, receiveDataForTableCSV } from '../actions/dashboard.action';

function* workerLoadDataTable(payload: any) {
  try {
    console.log(payload);
    const data: Response = yield call(getDataForTableAPI, payload);
    yield put(receiveDataForTable(data));
  } catch (error) {
    console.log(error)
  }
}

function* workerLoadDataTableCSV(payload: any) {
  try {
    console.log(payload);
    const data: Response = yield call(getDataForTableCSVAPI, payload);
    yield put(receiveDataForTableCSV(data));
  } catch (error) {
    console.log(error)
  }
}

function* workerLoadDataGraph(payload: any) {
  try {
    const data: Response = yield call(getDataForGraphAPI, payload);
    yield put(receiveDataForGraph(data));
  } catch (error) {
    console.log(error)
  }
}

function* workerLoadDataGraphGroup(payload: any) {
  try {
    const data: Response = yield call(getDataForGraphGroupAPI, payload);
    yield put(receiveDataForGraphGroup(data));
  } catch (error) {
    console.log(error)
  }
}

export default function* dashboard() {
  yield takeLatest(DASHBOARD_ACTIONS.DASHBOARD_ACTIONS_LOAD_DATA_TABLE, workerLoadDataTable);
  yield takeLatest(DASHBOARD_ACTIONS.DASHBOARD_ACTIONS_LOAD_DATA_TABLE_CSV, workerLoadDataTableCSV);
  yield takeLatest(DASHBOARD_ACTIONS.DASHBOARD_ACTIONS_LOAD_DATA_GRAPH, workerLoadDataGraph);
  yield takeLatest(DASHBOARD_ACTIONS.DASHBOARD_ACTIONS_LOAD_DATA_GRAPH_GROUP, workerLoadDataGraphGroup);
}