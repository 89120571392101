import { combineReducers, createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootSaga from './redux/sagas/index';
import dashboard from './redux/reducers/dashboard.reducer';
import dashboardKrugman from './redux/reducers/dashboard-krugman.reducer';


const sagaMiddleware = createSagaMiddleware();
export default createStore(combineReducers({
  dashboard,
  dashboardKrugman
}), composeWithDevTools(applyMiddleware(sagaMiddleware)));

sagaMiddleware.run(rootSaga);
