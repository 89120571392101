import React from 'react';
import './App.css';
import Root from './root';

const App: React.FC = (props) => {
  return (
      <Root />
  );
};

export default App;

