import { DASHBOARD_KRUGMAN_ACTIONS } from "./index.action";

// Load Form

export const loadForm = (payload: any) => ({
  type: DASHBOARD_KRUGMAN_ACTIONS.DASHBOARD_KRUGMAN_ACTIONS_FORM,
  payload
})

// Cargar indicadores

export const loadIndicators = (payload: any) => ({
  type: DASHBOARD_KRUGMAN_ACTIONS.DASHBOARD_KRUGMAN_ACTIONS_LOAD_INDICATORS,
  payload
})

export const receiveIndicators = (payload: any) => ({
  type: DASHBOARD_KRUGMAN_ACTIONS.DASHBOARD_KRUGMAN_ACTIONS_RECEIVE_INDICATORS,
  ...payload
})

// Cargar tabla

export const loadTable = (payload: any) => ({
  type: DASHBOARD_KRUGMAN_ACTIONS.DASHBOARD_KRUGMAN_ACTIONS_LOAD_DATA_TABLE,
  payload
})

export const receiveTable = (payload: any) => ({
  type: DASHBOARD_KRUGMAN_ACTIONS.DASHBOARD_KRUGMAN_ACTIONS_RECEIVE_DATA_TABLE,
  ...payload
})

// Load Graph Conditional

export const loadConditionalGraph = (payload: any) => ({
  type: DASHBOARD_KRUGMAN_ACTIONS.DASHBOARD_KRUGMAN_ACTIONS_LOAD_GRAPH_LINE_CONDITIONAL,
  payload
})

export const receiveConditionalGraph = (payload: any) => ({
  type: DASHBOARD_KRUGMAN_ACTIONS.DASHBOARD_KRUGMAN_ACTIONS_RECEIVE_GRAPH_LINE_CONDITIONAL,
  ...payload
})

// Load Graph LinePolyline

export const loadLinePolylineGraph = (payload: any) => ({
  type: DASHBOARD_KRUGMAN_ACTIONS.DASHBOARD_KRUGMAN_ACTIONS_LOAD_GRAPH_LINE_POLYLINE,
  payload
})

export const receiveLinePolylineGraph = (payload: any) => ({
  type: DASHBOARD_KRUGMAN_ACTIONS.DASHBOARD_KRUGMAN_ACTIONS_RECEIVE_GRAPH_LINE_POLYLINE,
  ...payload
})

// Load Graph BarMixMultiline

export const loadBarMultiLineGraph = (payload: any) => ({
  type: DASHBOARD_KRUGMAN_ACTIONS.DASHBOARD_KRUGMAN_ACTIONS_LOAD_GRAPH_BAR_MULTILINE,
  payload
})

export const receiveBarMultilineGraph = (payload: any) => ({
  type: DASHBOARD_KRUGMAN_ACTIONS.DASHBOARD_KRUGMAN_ACTIONS_RECEIVE_GRAPH_BAR_MULTILINE,
  ...payload
})

// Load Graph Histogram

export const loadHistogramGraph = (payload: any) => ({
  type: DASHBOARD_KRUGMAN_ACTIONS.DASHBOARD_KRUGMAN_ACTIONS_LOAD_GRAPH_HISTOGRAM,
  payload
})

export const receiveHistogramGraph = (payload: any) => ({
  type: DASHBOARD_KRUGMAN_ACTIONS.DASHBOARD_KRUGMAN_ACTIONS_RECEIVE_GRAPH_HISTOGRAM,
  ...payload
})
