import moment from "moment";
import { DASHBOARD_ACTIONS } from "../actions/index.action";

let defaultState = {
  listDataGraph: null,
  listDataGraphLoaded: false,
  listDataGraphLoading: false,

  listDataGraphGroup: null,
  listDataGraphGroupLoaded: false,
  listDataGraphGroupLoading: false,

  listDataTable: null,
  listDataTableLoaded: false,
  listDataTableLoading: false,
  filters: {
    start: moment().subtract(10, 'days').unix() * 1000,
    end: moment().subtract(1, 'hours').unix() * 1000,
    commerce: "12424"
  },
  pageSize: 10,
  currentPage: 1,
  total: 0,

  listDataTableCSV: null,
  listDataTableLoadedCSV: false,
  listDataTableLoadingCSV: false,
}

const dashboard = (state = defaultState, action: any) => {
  switch (action.type) {
    case DASHBOARD_ACTIONS.DASHBOARD_ACTIONS_LOAD_DATA_TABLE:
      return {
        ...state,
        listDataTableLoaded: false,
        listDataTable: null,
        listDataTableLoading: true,
        currentPage: action.pagination.current,
        pageSize: action.pagination.pageSize,
      }
    case DASHBOARD_ACTIONS.DASHBOARD_ACTIONS_RECEIVE_DATA_TABLE:
      return {
        ...state,
        listDataTableLoaded: true,
        listDataTable: action.data,
        listDataTableLoading: false,
        total: action.total,
      }
     case DASHBOARD_ACTIONS.DASHBOARD_ACTIONS_LOAD_DATA_TABLE_CSV:
      return {
        ...state,
        listDataTableLoadedCSV: false,
        listDataTableCSV: null,
        listDataTableLoadingCSV: true,
        currentPage: action.pagination.current,
        pageSize: action.pagination.pageSize,
      }
    case DASHBOARD_ACTIONS.DASHBOARD_ACTIONS_RECEIVE_DATA_TABLE_CSV:
      return {
        ...state,
        listDataTableLoadedCSV: true,
        listDataTableCSV: action.data,
        listDataTableLoadingCSV: false,
        total: action.total,
      }
    case DASHBOARD_ACTIONS.DASHBOARD_ACTIONS_LOAD_DATA_GRAPH:
      return {
        ...state,
        listDataGraphLoaded: false,
        listDataGraph: null,
        listDataGraphLoading: true,
      }
    case DASHBOARD_ACTIONS.DASHBOARD_ACTIONS_RECEIVE_DATA_GRAPH:
      return {
        ...state,
        listDataGraphLoaded: true,
        listDataGraph: action.data,
        listDataGraphLoading: false,
      }
    case DASHBOARD_ACTIONS.DASHBOARD_ACTIONS_LOAD_DATA_GRAPH_GROUP:
      return {
        ...state,
        listDataGraphGroupLoaded: false,
        listDataGraphGroup: null,
        listDataGraphGroupLoading: true,
      }
    case DASHBOARD_ACTIONS.DASHBOARD_ACTIONS_RECEIVE_DATA_GRAPH_GROUP:
      return {
        ...state,
        listDataGraphGroupLoaded: true,
        listDataGraphGroup: action.data,
        listDataGraphGroupLoading: false,
      }
    default:
      return state
  }
}

export default dashboard;