import { takeLatest, put, call } from "redux-saga/effects";
import { Response } from "../../models/Response";
import { getDataTableAPI, getDataIndicatorAPI, getDataConditionalGraphAPI, getDataLinePolylineGraphAPI, getDataBarMultiLineGraphAPI, getDataHistogramGraphAPI } from '../../services/dashboard-krugman.service';
import { DASHBOARD_KRUGMAN_ACTIONS } from '../actions/index.action';
import { receiveTable, receiveIndicators, receiveConditionalGraph, receiveLinePolylineGraph, receiveBarMultilineGraph, receiveHistogramGraph } from '../actions/dashboard-krugman.action';

function* workerLoadDataTable(payload: any) {
  try {
    const data: Response = yield call(getDataTableAPI, payload);
    yield put(receiveTable(data));
  } catch (error) {
    console.log(error)
  }
}

function* workerLoadIndicator(payload: any) {
  try {
    const data: Response = yield call(getDataIndicatorAPI, payload);
    yield put(receiveIndicators(data));
  } catch (error) {
    console.log(error)
  }
}

function* workerLoadConditionalGraph(payload: any) {
  try {
    const data: Response = yield call(getDataConditionalGraphAPI, payload);
    yield put(receiveConditionalGraph(data));
  } catch (error) {
    console.log(error)
  }
}

function* workerLoadLinePolylineGraph(payload: any) {
  try {
    const data: Response = yield call(getDataLinePolylineGraphAPI, payload);
    yield put(receiveLinePolylineGraph(data));
  } catch (error) {
    console.log(error)
  }
}

function* workerLoadBarMultiLineGraph(payload: any) {
  try {
    const data: Response = yield call(getDataBarMultiLineGraphAPI, payload);
    yield put(receiveBarMultilineGraph(data));
  } catch (error) {
    console.log(error)
  }
}

function* workerLoadHistogramGraph(payload: any) {
  try {
    const data: Response = yield call(getDataHistogramGraphAPI, payload);
    yield put(receiveHistogramGraph(data));
  } catch (error) {
    console.log(error)
  }
}

export default function* dashboardKrugman() {
  yield takeLatest(DASHBOARD_KRUGMAN_ACTIONS.DASHBOARD_KRUGMAN_ACTIONS_LOAD_DATA_TABLE, workerLoadDataTable);
  yield takeLatest(DASHBOARD_KRUGMAN_ACTIONS.DASHBOARD_KRUGMAN_ACTIONS_LOAD_INDICATORS, workerLoadIndicator);
  yield takeLatest(DASHBOARD_KRUGMAN_ACTIONS.DASHBOARD_KRUGMAN_ACTIONS_LOAD_GRAPH_LINE_CONDITIONAL, workerLoadConditionalGraph);
  yield takeLatest(DASHBOARD_KRUGMAN_ACTIONS.DASHBOARD_KRUGMAN_ACTIONS_LOAD_GRAPH_LINE_POLYLINE, workerLoadLinePolylineGraph);
  yield takeLatest(DASHBOARD_KRUGMAN_ACTIONS.DASHBOARD_KRUGMAN_ACTIONS_LOAD_GRAPH_BAR_MULTILINE, workerLoadBarMultiLineGraph);
  yield takeLatest(DASHBOARD_KRUGMAN_ACTIONS.DASHBOARD_KRUGMAN_ACTIONS_LOAD_GRAPH_HISTOGRAM, workerLoadHistogramGraph);
}