import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const HomeView = React.lazy(() => import('./layout/HomeLayout'));
const LoginView = React.lazy(() => import('./layout/AuthLayout'));

const Root: React.FC<any> = () => {

  return (
    <Suspense fallback={<div></div>}>
      <Router>
        <Switch>
          <Route component={HomeView} path='/home' />
          <Route component={LoginView} exact path='/' />
          <Route>
            <Redirect to="/" />
          </Route>
        </Switch>
      </Router>
    </Suspense>
  )
};

export default connect()(Root);