import { DASHBOARD_ACTIONS } from "./index.action";

export const getDataForTable = (payload: any, pagination: any) => ({
  type: DASHBOARD_ACTIONS.DASHBOARD_ACTIONS_LOAD_DATA_TABLE,
  payload,
  pagination
})

export const getDataForTableCSV = (payload: any, pagination: any) => ({
  type: DASHBOARD_ACTIONS.DASHBOARD_ACTIONS_LOAD_DATA_TABLE_CSV,
  payload,
  pagination
})

export const getDataForGraph = (payload: any) => ({
  type: DASHBOARD_ACTIONS.DASHBOARD_ACTIONS_LOAD_DATA_GRAPH,
  ...payload
})

export const getDataForGraphGroup = (payload: any) => ({
  type: DASHBOARD_ACTIONS.DASHBOARD_ACTIONS_LOAD_DATA_GRAPH_GROUP,
  ...payload
})

export const receiveDataForTable = (payload: any) => ({
  type: DASHBOARD_ACTIONS.DASHBOARD_ACTIONS_RECEIVE_DATA_TABLE,
  ...payload
})

export const receiveDataForTableCSV = (payload: any) => ({
  type: DASHBOARD_ACTIONS.DASHBOARD_ACTIONS_RECEIVE_DATA_TABLE_CSV,
  ...payload
})

export const receiveDataForGraph = (payload: any) => ({
  type: DASHBOARD_ACTIONS.DASHBOARD_ACTIONS_RECEIVE_DATA_GRAPH,
  ...payload
})

export const receiveDataForGraphGroup = (payload: any) => ({
  type: DASHBOARD_ACTIONS.DASHBOARD_ACTIONS_RECEIVE_DATA_GRAPH_GROUP,
  ...payload
})