export const getDataTableAPI = async(payload: any) => {
  const options = {
    method: "GET",
    headers: {
      'Content-type': 'application/json',
    }
  }

  try {
    const response = await fetch(`${process.env.REACT_APP_API_ES_CONNECTOR_API}/dashboard-krugman/data-transactions?start=${payload.payload.start}&end=${payload.payload.end}`, options);
    return response.json()
  } catch (error) {
    console.error(error);
  }
}

export const getDataIndicatorAPI = async(payload: any) => {
  console.log('getDataIndicatorAPI payload', payload);
  const options = {
    method: "GET",
    headers: {
      'Content-type': 'application/json',
    }
  }

  try {
    const response = await fetch(`${process.env.REACT_APP_API_ES_CONNECTOR_API}/dashboard-krugman/indicators?start=${payload.payload.start}&end=${payload.payload.end}`, options);
    return response.json()
  } catch (error) {
    console.error(error);
  }
}

export const getDataConditionalGraphAPI = async(payload: any) => {
  const options = {
    method: "GET",
    headers: {
      'Content-type': 'application/json',
    }
  }

  try {
    const response = await fetch(`${process.env.REACT_APP_API_ES_CONNECTOR_API}/dashboard-krugman/graph-conditional?start=${payload.payload.start}&end=${payload.payload.end}`, options);
    return response.json()
  } catch (error) {
    console.error(error);
  }
}

export const getDataLinePolylineGraphAPI = async(payload: any) => {
  const options = {
    method: "GET",
    headers: {
      'Content-type': 'application/json',
    }
  }

  try {
    const response = await fetch(`${process.env.REACT_APP_API_ES_CONNECTOR_API}/dashboard-krugman/graph-line-polyline?start=${payload.payload.start}&end=${payload.payload.end}`, options);
    return response.json()
  } catch (error) {
    console.error(error);
  }
}

export const getDataBarMultiLineGraphAPI = async(payload: any) => {
  const options = {
    method: "GET",
    headers: {
      'Content-type': 'application/json',
    }
  }

  try {
    const response = await fetch(`${process.env.REACT_APP_API_ES_CONNECTOR_API}/dashboard-krugman/graph-multi-line?start=${payload.payload.start}&end=${payload.payload.end}`, options);
    return response.json()
  } catch (error) {
    console.error(error);
  }
}

export const getDataHistogramGraphAPI = async(payload: any) => {
  const options = {
    method: "GET",
    headers: {
      'Content-type': 'application/json',
    }
  }

  try {
    const response = await fetch(`${process.env.REACT_APP_API_ES_CONNECTOR_API}/dashboard-krugman/graph-histogram?start=${payload.payload.start}&end=${payload.payload.end}`, options);
    return response.json()
  } catch (error) {
    console.error(error);
  }
}